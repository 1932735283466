@import '../../../way-lib/src/theme/fonts/worksans.scss';

:root {
  --ion-font-family: 'Work Sans';
  font-family: 'Work Sans';
  font-size: 12px;

  ion-text {
    &.font-bold {
      font-weight: bold;
    }
  }
}

@import '../../../way-lib/src/theme/fonts/josefinsans.scss';
