ion-range {
  &.best-price {
    --knob-size: 20px;
    --knob-background: white;
    --knob-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    --bar-background-active: transparent;
    --bar-background: linear-gradient(270deg, #2DDF00 0%, #FFE600 50%, #FF0000 100%), #C4C4C4;;
    --bar-height: 10px;
    --bar-border-radius: 4px;
  }
}

ion-input, ion-select, ion-datetime, ion-textarea, ion-checkbox {
  --checkmark-color: var(--way-style-blockbg);
  background: var(--way-style-blockbg) !important;
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(34, 26, 56, 0.05);
  --padding-start: 16px !important;
}