:root {
  remix-icon {
    display: block;

    &.size-lg {
      width: 22px;
      height: 22px;
    }

    &.size-md {
      width: 20px;
      height: 20px;
    }

    &.size-sm {
      width: 17px;
      height: 17px;
    }

    &.size-xs {
      width: 13px;
      height: 13px;
    }
  }
}
