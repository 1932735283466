ion-card {
  &.content-card {
    --background: var(--way-style-blockbg);
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.02),
    0 2px 2px 0 rgba(0, 0, 0, 0.01), 0 1px 5px 0 rgba(0, 0, 0, 0.01);
    -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.02),
    0 2px 2px 0 rgba(0, 0, 0, 0.01), 0 1px 5px 0 rgba(0, 0, 0, 0.01);
    border-radius: 3px;
    -webkit-margin-start: 0;
    margin-inline-start: 0;
    -webkit-margin-end: 0;
    margin-inline-end: 0;
  }
}
