/* josefin-sans-100 */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 100;
  src: local(''),
  url('../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf') format('truetype') /* Safari, Android, iOS */
}

/* josefin-sans-200 */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 200;
  src: local(''),
  url('../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf') format('truetype') /* Safari, Android, iOS */
}

/* josefin-sans-300 */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 300;
  src: local(''),
  url('../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf') format('truetype') /* Safari, Android, iOS */
}

/* josefin-sans-400 */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf') format('truetype') /* Safari, Android, iOS */
}

/* josefin-sans-500 */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf') format('truetype') /* Safari, Android, iOS */
}

/* josefin-sans-600 */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 600;
  src: local(''),
  url('../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf') format('truetype') /* Safari, Android, iOS */
}

/* josefin-sans-700 */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf') format('truetype') /* Safari, Android, iOS */
}

/* josefin-sans-800 */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 800;
  src: local(''),
  url('../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf') format('truetype') /* Safari, Android, iOS */
}

/* josefin-sans-900 */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 900;
  src: local(''),
  url('../../assets/fonts/josefin-sans/JosefinSans-VariableFont_wght.ttf') format('truetype') /* Safari, Android, iOS */
}

/* josefin-sans-100italic */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 100;
  src: local(''),
  url('../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf') format('truetype') /* Safari, Android, iOS */
}

/* josefin-sans-200italic */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 200;
  src: local(''),
  url('../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf') format('truetype') /* Safari, Android, iOS */
}

/* josefin-sans-300italic */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 300;
  src: local(''),
  url('../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf') format('truetype') /* Safari, Android, iOS */
}

/* josefin-sans-400italic */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 400;
  src: local(''),
  url('../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf') format('truetype') /* Safari, Android, iOS */
}

/* josefin-sans-500italic */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 500;
  src: local(''),
  url('../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf') format('truetype') /* Safari, Android, iOS */
}

/* josefin-sans-600italic */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 600;
  src: local(''),
  url('../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf') format('truetype') /* Safari, Android, iOS */
}

/* josefin-sans-700italic */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 700;
  src: local(''),
  url('../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf') format('truetype') /* Safari, Android, iOS */
}

/* josefin-sans-800italic */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 800;
  src: local(''),
  url('../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf') format('truetype') /* Safari, Android, iOS */
}

/* josefin-sans-900italic */
@font-face {
  font-family: 'Josefin Sans';
  font-style: italic;
  font-weight: 900;
  src: local(''),
  url('../../assets/fonts/josefin-sans/JosefinSans-Italic-VariableFont_wght.ttf') format('truetype') /* Safari, Android, iOS */
}