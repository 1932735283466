/* work-sans-100 - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 100;
  src: url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-200 - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 200;
  src: url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-300 - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-regular - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-regular.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-500 - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-600 - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-700 - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-800 - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-900 - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 900;
  src: url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-200italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 200;
  src: url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200italic.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-200italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-100italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 100;
  src: url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100italic.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-100italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-300italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300italic.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-300italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-500italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 500;
  src: url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500italic.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-500italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-700italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-700italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-600italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 600;
  src: url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600italic.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-600italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-800italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 800;
  src: url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800italic.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-800italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* work-sans-900italic - vietnamese_latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 900;
  src: url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900italic.woff') format('woff'), /* Modern Browsers */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/fonts/work-sans/work-sans-v8-vietnamese_latin-ext_latin-900italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}
